import { createSlice } from '@reduxjs/toolkit';
import { set } from 'lodash';

type dlrnHernrCodfouByspecifyingReferenceSlice = {
  dlrnHernrCodfouByspecifyingReferenceActive: boolean;
  isLoading: boolean;
  typeEquivalence: string;
};

const initialState: dlrnHernrCodfouByspecifyingReferenceSlice = {
  dlrnHernrCodfouByspecifyingReferenceActive: false,
  isLoading: false,
  typeEquivalence: '',
};

const dlrnHernrCodfouByspecifyingReferenceSlice = createSlice({
  name: 'dlrnHernrCodfouByspecifyingReferenceSlice',
  initialState,
  reducers: {
    setDlrnHernrCodfouByspecifyingReferenceActive(state, { payload }) {
      state.dlrnHernrCodfouByspecifyingReferenceActive = payload;
    },
    setIsdlrnHernrCodfouByspecifyingReferenceLoading(state, { payload }) {
      state.isLoading = payload;
    },
    setTypetEquivalence(state, { payload }) {
      state.typeEquivalence = payload;
    }

  },

});

export const { setDlrnHernrCodfouByspecifyingReferenceActive, setIsdlrnHernrCodfouByspecifyingReferenceLoading, setTypetEquivalence } = dlrnHernrCodfouByspecifyingReferenceSlice.actions;

export default dlrnHernrCodfouByspecifyingReferenceSlice.reducer;
