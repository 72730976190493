import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IImportFileNewV2 } from 'interfaces/price';


type IData = {
  importReference: IImportFileNewV2 | null;
};

const initialState: IData = {
  importReference: null,
};

export const importReferenceSlice = createSlice({
  name: 'importReferenceData',
  initialState,
  reducers: {
    setImportReferenceData: (state, action: PayloadAction<IImportFileNewV2 | null>) => {
      state.importReference = action.payload;
    },
    removeImportReferenceData: (state) => {
      state.importReference = null;
    },

  },
});

export const { setImportReferenceData, removeImportReferenceData } = importReferenceSlice.actions;

export default importReferenceSlice.reducer;
