import React from 'react';

import './AutomotorBuyerHomePage.scss';
import CheckEquivalencesList from './checkEquivalences/CheckEquivalencesList';

type Props = {};

const AutomotorBuyerHomePage: React.FC<Props> = () => {


  return <div className="automotor-buyer-home-page">

    <CheckEquivalencesList per_page={5} noPaginate buttonList />


  </div>;

};

export default AutomotorBuyerHomePage;


