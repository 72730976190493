
import { createAsyncThunk } from '@reduxjs/toolkit';
import useHttp from 'utils/hooks/useHttp';
import { IAddToCartParams } from '../../models/car-parts.model';
import { notify } from '../../utils/marketplace';

export const addToCartAction = createAsyncThunk('orders', async (params: IAddToCartParams[], { rejectWithValue }) => {
  try {

    const http = useHttp();
    const { data } = await http.order.addToCart(params);

    notify('SUCCESS', 'success');

    return data;
  } catch (e) {
    console.log(e);
    notify('ERROR');

    return rejectWithValue('add to cart error');
  }
});