
import { IImportFile, IImportFileNew, IImportFileNewV2, IPriceReference } from 'interfaces/price';
import { IHttpResponse, serverAxios } from '../../http';

class PriceHttp {
  private static route = '/automotor-price/reference';
  private static import = '/automotor-price/reference/import';
  private static export = '/automotor-price/reference/export';
  private static default = '/automotor-price/reference/export_default';
  public async getPrice(param: IPriceReference): Promise<IHttpResponse<IImportFileNew[]>> {
    return serverAxios.get(PriceHttp.route, { params: param });
  }

  public async import(data: FormData): Promise<IHttpResponse<IImportFileNewV2>> {
    return serverAxios.post(PriceHttp.import, data);
  }

  public async export(data: IImportFile[] | IImportFileNew[]): Promise<IHttpResponse<Blob>> {
    return serverAxios.post(PriceHttp.export, data, { responseType: 'blob' });
  }
  public async exportDefault(): Promise<IHttpResponse<Blob>> {
    return serverAxios.get(PriceHttp.default, { responseType: 'blob' });
  }
}

export default PriceHttp;
