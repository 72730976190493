import React from 'react';
import { useTranslation } from 'react-i18next';

import { setDlrnHernrCodfouByspecifyingReferenceActive, setTypetEquivalence } from 'store/slices/dlrnHernrCodfouByspecifyingReference.slice';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faListAlt } from '@fortawesome/free-regular-svg-icons';


const c = 'automotor-menu-navbar';

type Props = {
  type: string;
  title: string;
};


const DlrnHernrCodfouByspecifyingReference: React.FC<Props> = (prosp) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleDlrnHernrCodfouByspecifyingReference = () => {

    dispatch(setTypetEquivalence(prosp.type));
    dispatch(setDlrnHernrCodfouByspecifyingReferenceActive(true));

  };

  return (
    <div className={`${c}__item--parent__children__item--title`} onClick={handleDlrnHernrCodfouByspecifyingReference}>
      <FontAwesomeIcon icon={faListAlt} />
      <span>{t('_' + prosp.title)}</span>
    </div>
  );
};

export default DlrnHernrCodfouByspecifyingReference;
