import React, { useEffect } from 'react';
import './SearchCatalog.scss';
import { IUser } from 'models/IUser';
import { Trans, useTranslation } from 'react-i18next';
import GlobalSearchInput from '../../../../../components/inputs/GlobalSearchInput/GlobalSearchInput';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/store';
import useHttp from 'utils/hooks/useHttp';
import { removeInfoManufacturerSupplier, setInfoManufacturerSupplier } from 'store/reducers/manufacturer-supplier';


type Props = {
  handleLogout: () => void;
  user: IUser | null;
};

const SearchCatalogView: React.FC<Props> = () => {
  const { t } = useTranslation();
  const http = useHttp();
  const dispatch = useDispatch();

  const manufacturerSupplier = useSelector(
    (state: RootState) => state.manufacturerSupplier.infoManufacturerSupplier
  );

  useEffect(() => {
    if (manufacturerSupplier == null) {
      http.manufacturerSupplier.getManufacturerSupplier().then((res) => {
        dispatch(setInfoManufacturerSupplier(res.data));
      }).catch(() => {
        dispatch(removeInfoManufacturerSupplier());
      });
    }

  }, [manufacturerSupplier]);

  return (
    <div className="header-search-espace-catalog-sidebar">
      <div className="title-sidebar">
        <Trans
          i18nKey="_universal_catalog_header"
          components={{ b: <b /> }}
          values={{
            manufacturer: manufacturerSupplier?.manufacturer || 0,
            supplier: manufacturerSupplier?.supplier || 0,
          }}
        />
      </div>

      <div className="header-search-input">
        <GlobalSearchInput
          placeholder={t('_free_search')}
          size="l"
          withIcon
        />
      </div>
    </div>
  );
};

SearchCatalogView.displayName = 'SearchCatalogView';

export default SearchCatalogView;
