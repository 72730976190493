
import { IHttpResponse, serverAxios } from '../../http';

class ManufacturerSupplierHttp {
  private static route = 'manufacturer-supplier';

  public getManufacturerSupplier(): Promise<IHttpResponse<IManufacturerSupplier>> {
    return serverAxios.get(ManufacturerSupplierHttp.route);
  }

}

export type IManufacturerSupplier = {
  manufacturer: number;
  supplier: number;
};

export default ManufacturerSupplierHttp;
