import React from 'react';
import { faBook, faCartShopping, faComment, faGear, faInfo, faListSquares, faNewspaper, faPowerOff, faEuro } from '@fortawesome/free-solid-svg-icons';
import { ReactComponent as PersonalSpace } from 'assets/images/space-personal.svg';
import { ReactComponent as SendCommand } from 'assets/images/send-command.svg';
import { ReactComponent as ProviderIcon } from 'assets/images/service-provider-icon.svg';
import { ReactComponent as DocsIcon } from 'assets/images/documents.svg';
import { ReactComponent as Discount } from 'assets/images/discount.svg';
import { ReactComponent as NotificationBell } from 'assets/images/notification-bell.svg';
import { ReactComponent as IndustrialIcon } from 'assets/images/brake-icon.svg';
import { ReactComponent as RestrictionIcon } from 'assets/images/flag-line-icon.svg';
import { ReactComponent as AllowedParts } from 'assets/images/allowed-parts.svg';
import { ReactComponent as OrderInProgress } from 'assets/images/order_in_progress.svg';
import { ReactComponent as PriceManagement } from 'assets/images/price-management.svg';
import { ReactComponent as Client } from 'assets/images/business-team-icon.svg';
import { ReactComponent as UploadCsv } from 'assets/images/upload.svg';
import { ReactComponent as PartReplacement } from 'assets/images/part-replacement.svg';
import { ReactComponent as PriceTag } from 'assets/images/price-tag.svg';
import { ReactComponent as LoadingProvidersPrice } from 'assets/images/price-list-icon.svg';
import { ReactComponent as AutomotorOffer } from 'assets/images/offer-automotor.svg';
import { ReactComponent as TaskCheckmark } from 'assets/images/task-checkmark-icon.svg';
import { CLIENT_ROLES, ROLE_COMMERCIAL_DIRECTOR, ROLE_SELLER, ROLE_BUYER } from 'utils/constants/roles';
import MenuUploadOrder from './custom-components/MenuUploadOrder';
import MenuSubmitQuotation from './custom-components/MenuSubmitQuotation';
import HidePrice from './custom-components/HidePrice/HidePrice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MenuLogout from './custom-components/MenuLogout';
import MenuEstore from './custom-components/MenuEstore';
import DlrnHernrCodfouByspecifyingReference from '../../espace-automotor-buyer-layout/navbar/dlrnHernrCodfouByspecifyingReference/DlrnHernrCodfouByspecifyingReference';
import { IUser } from 'models/IUser';

const hasPermission = (roles: string[] = [], user): boolean => {

  return roles.some((role: string) => user?.roles.includes(role));
};

function openPDFInNewTab(): void {
  window.open('/guides/distributor_guide.pdf', '_blank');
}

export interface IMenuItem {
  name: string;
  role: string[];
  image: React.ComponentType | React.ReactElement;
  callback?: () => void | null;
  url?: string;
  custom?: React.ReactNode;
  children?: (IMenuItem | { separator: boolean })[];
  disabled?: boolean;
}

export const getAutomotorMenuList = (user: IUser): IMenuItem[] => {
  return [
    {
      name: '_order',
      role: [ROLE_SELLER, ...CLIENT_ROLES],
      image: SendCommand,
      callback: (): null => null,
      children: [
        {
          name: '_upload_your_proforma_file',
          role: [],
          image: UploadCsv,
          custom: <MenuUploadOrder />
        },
        {
          name: '_order_in_progress',
          role: [],
          image: OrderInProgress,
          url: '/automotor-online/order/list',
        },
        {
          name: '_waiting_offers',
          role: [ROLE_SELLER, ROLE_COMMERCIAL_DIRECTOR],
          image: <FontAwesomeIcon icon={faListSquares} />,
          url: '/automotor-online/area/waiting-offers',
        },
        { separator: true },
        {
          name: '_submit_quotation',
          role: [],
          image: UploadCsv,
          custom: <MenuSubmitQuotation />,
        },
        { separator: true },
        {
          name: '_order_availability',
          role: [],
          image: OrderInProgress,
          url: '/automotor-online/order/remaining',
        },
        {
          name: '_financial',
          role: [],
          image: OrderInProgress,
          url: '/automotor-online/order/financial',
        },
        { separator: true },
        {
          name: '_your_shopping_carts',
          role: [],
          image: <FontAwesomeIcon icon={faCartShopping} />,
          url: '/automotor-online/offer/cart',
        },
        { separator: true },
        {
          name: '_offer_automotor',
          role: [],
          image: AutomotorOffer,
          url: '/automotor-online/offer',
        },
      ],
    },
    {
      name: '_configuration',
      role: [ROLE_SELLER, ROLE_BUYER],
      image: AllowedParts,
      callback: (): null => null,
      children: [
        {
          name: '_loading_providers_price',
          role: [],
          image: PriceTag,
          url: '/automotor-online/price/loading-providers',
        },

        {
          name: '_price_management',
          role: [],
          image: PriceManagement,
          url: '/automotor-online/price/management',
        },
        { separator: hasPermission([ROLE_SELLER, ROLE_BUYER], user) },
        {
          name: '_parts_replacement',
          role: [ROLE_SELLER, ROLE_BUYER],
          image: PartReplacement,
          url: '/automotor-online/price/replacement',
        },
        { separator: hasPermission([ROLE_SELLER], user) },
        {
          name: '_provider_franco',
          role: [ROLE_SELLER, ROLE_BUYER],
          image: ProviderIcon,
          url: '/automotor-online/providers',
        },
        {
          name: '_minimum_order_value',
          role: [ROLE_SELLER],
          image: ProviderIcon,
          url: '/automotor-online/order-value',
        },
        { separator: hasPermission([ROLE_SELLER], user) },
        {
          name: '_proforma_mapping',
          role: [ROLE_SELLER],
          image: DocsIcon,
          url: '/automotor-online/proforma-mapping',
        },
        {
          name: '_country_restriction',
          role: [ROLE_SELLER],
          image: RestrictionIcon,
          url: '/automotor-online/country-restriction',
        },
        {
          name: '_allowed_list',
          role: [ROLE_SELLER],
          image: AllowedParts,
          url: '/automotor-online/allowed-list',
        },
        { separator: hasPermission([ROLE_SELLER], user) },
        {
          name: '_industrial_parts',
          role: [ROLE_SELLER, ROLE_BUYER],
          image: IndustrialIcon,
          url: '/automotor-online/industrial',
        },
        { separator: hasPermission([ROLE_SELLER, ROLE_BUYER], user) },
        {
          name: '_select_client',
          role: [ROLE_SELLER],
          image: PersonalSpace,
          url: '/automotor-online/area/select',
        },
        { separator: hasPermission([ROLE_SELLER], user) },

        {
          name: '_promotions',
          role: [ROLE_SELLER],
          image: Discount,
          url: '/automotor-online/promotions',
        },
        {
          name: hasPermission([...CLIENT_ROLES], user) ? '_news_and_promotions' : '_news',
          role: [ROLE_SELLER, ROLE_BUYER],
          image: <FontAwesomeIcon icon={faNewspaper} />,
          url: '/automotor-online/news',
        },
        {
          name: '_price_list',
          role: [ROLE_SELLER],
          image: LoadingProvidersPrice,
          url: '/automotor-online/price/tariffs',
        },
        { separator: hasPermission([ROLE_BUYER], user) },
        {
          name: 'check_equivalences_for_iam',
          role: [ROLE_BUYER],
          image: UploadCsv,
          custom: <DlrnHernrCodfouByspecifyingReference type="IAM" title="check_equivalences_for_iam" />
        },

        {
          name: 'check_equivalences_for_oem',
          role: [ROLE_BUYER],
          image: UploadCsv,
          custom: <DlrnHernrCodfouByspecifyingReference type="OEM" title="check_equivalences_for_oem" />
        },
      ],
    },
    {
      name: '_orders',
      role: [ROLE_COMMERCIAL_DIRECTOR],
      image: Client,
      callback: (): null => null,
      children: [
        {
          name: '_waiting_confirmation',
          role: [],
          image: TaskCheckmark,
          url: '/automotor-online/area/waiting-confirmation',
        },
        {
          name: '_order_in_progress',
          role: [],
          image: OrderInProgress,
          url: '/automotor-online/order/list',
        },
        { separator: true },
        {
          name: '_order_availability',
          role: [],
          image: OrderInProgress,
          url: '/automotor-online/order/remaining',
        },
        {
          name: '_financial',
          role: [],
          image: OrderInProgress,
          url: '/automotor-online/order/financial',
        },
        { separator: true },
        {
          name: '_promotions',
          role: [],
          image: Discount,
          url: '/automotor-online/promotions',
        },
        {
          name: '_news',
          role: [],
          image: <FontAwesomeIcon icon={faNewspaper} />,
          url: '/automotor-online/news',
        },
        {
          name: '_messages',
          role: [],
          image: <FontAwesomeIcon icon={faComment} />,
          url: '/automotor-online/messages',
        },
        {
          name: '_notifications',
          role: [],
          image: NotificationBell,
          url: '/automotor-online/notifications',
        },
        { separator: true },
        {
          name: '_select_client',
          role: [],
          image: PersonalSpace,
          url: '/automotor-online/area/select',
        },
      ],
    },
    {
      name: '_my_account',
      role: [ROLE_SELLER, ...CLIENT_ROLES, ROLE_BUYER],
      image: PersonalSpace,
      callback: (): null => null,
      children: [
        {
          name: '_settings',
          role: [ROLE_SELLER, ...CLIENT_ROLES],
          image: <FontAwesomeIcon icon={faGear} />,
          url: '/automotor-online/settings',
        },
        {
          name: '_my_info',
          role: [ROLE_SELLER, ...CLIENT_ROLES, ROLE_BUYER],
          image: <FontAwesomeIcon icon={faInfo} />,
          url: '/automotor-online/my-profile',
        },
        { separator: hasPermission([ROLE_SELLER, ...CLIENT_ROLES, ROLE_BUYER], user) },
        {
          name: '_messages',
          role: [ROLE_SELLER, ...CLIENT_ROLES],
          image: <FontAwesomeIcon icon={faComment} />,
          url: '/automotor-online/messages',
        },
        {
          name: '_notifications',
          role: [ROLE_SELLER, ...CLIENT_ROLES],
          image: NotificationBell,
          url: '/automotor-online/notifications',
        },
        { separator: hasPermission([ROLE_SELLER, ...CLIENT_ROLES], user) },
        {
          name: '_automotor_catalog_link',
          role: [ROLE_SELLER, ...CLIENT_ROLES, ROLE_BUYER],
          image: <FontAwesomeIcon icon={faNewspaper} />,
          url: '/universal-catalog',
        },
        {
          name: '_my_eshop',
          role: [ROLE_SELLER, ...CLIENT_ROLES],
          image: <FontAwesomeIcon icon={faNewspaper} />,
          custom: <MenuEstore />,
        },

        { separator: hasPermission([ROLE_SELLER, ...CLIENT_ROLES, ROLE_BUYER], user) },
        {
          name: '_hide_price',
          role: [ROLE_SELLER, ...CLIENT_ROLES],
          image: <FontAwesomeIcon icon={faEuro} />,
          custom: <HidePrice />
        },
        { separator: hasPermission([ROLE_SELLER, ...CLIENT_ROLES], user) },
        {
          name: '_learn_about_e-reliable',
          role: [ROLE_SELLER, ...CLIENT_ROLES],
          image: <FontAwesomeIcon icon={faBook} />,
          callback: openPDFInNewTab,
        },
        { separator: hasPermission([ROLE_SELLER, ...CLIENT_ROLES], user) },
        {
          name: '_logout',
          role: [],
          image: <FontAwesomeIcon icon={faPowerOff} />,
          custom: <MenuLogout />,
        }
      ]
    },
  ];
};