
import { IHttpResponse, serverAxios } from '../../http';

import { CancelToken } from 'axios';

class CheckEquivalenceHttp {
  private static equivalenceAIMRoute = 'tecdoc/iam-suppliers';
  private static equivalenceOEMRoute = 'tecdoc/oem-suppliers';
  private static equivalenceSupplierRoute = 'tecdoc/suppliers';
  private static equivalenceListRoute = 'buyer/check-equivalences';


  public getEquivalenceAIM(
    urlParams: { per_page: number; page: number, q?: string },
    cancelToken: CancelToken
  ): Promise<IHttpResponse<IResponse>> {
    return serverAxios.get(CheckEquivalenceHttp.equivalenceAIMRoute, {
      params: urlParams,
      cancelToken: cancelToken
    });
  }

  public getEquivalenceOEM(
    urlParams: { per_page: number; page: number, q?: string },
    cancelToken: CancelToken
  ): Promise<IHttpResponse<IResponse>> {
    return serverAxios.get(CheckEquivalenceHttp.equivalenceOEMRoute, {
      params: urlParams,
      cancelToken: cancelToken
    });
  }
  public getEquivalenceSupplier(
    urlParams: { per_page: number; page: number, q?: string },
    cancelToken: CancelToken
  ): Promise<IHttpResponse<IResponse>> {
    return serverAxios.get(CheckEquivalenceHttp.equivalenceSupplierRoute, {
      params: urlParams,
      cancelToken: cancelToken
    });
  }
  public getEquivalenceList(
    urlParams: { per_page?: number; page?: number }
  ): Promise<IHttpResponse<IResponseList>> {
    return serverAxios.get(CheckEquivalenceHttp.equivalenceListRoute, {
      params: urlParams
    });
  }
}

export interface IResponse {
  page: number;
  per_page: number;
  total_count: number;
  total_pages: number;
  data: Array<ICheckEquivalenceListDetails>;
}

export interface ICheckEquivalenceListDetails {
  name: string;
  hernr: string;
}

export interface ICheckEquivalenceList {
  id: number
  hernr: string;
  hernr_eq: string;
  type: string;
  filepath: string;
  created_at: number;
  created_by: string;
}

export interface IResponseList {
  page: number;
  per_page: number;
  total_count: number;
  total_pages: number;
  data: Array<ICheckEquivalenceList>;
}

export default CheckEquivalenceHttp;
