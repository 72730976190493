import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NewReferenceSearch from './new-reference-search/NewReferenceSearch';
import useHttp from 'utils/hooks/useHttp';
import { IImportFileNew, IImportFileNewV2 } from 'interfaces/price';
import { notify } from 'utils/marketplace';
import TariffTable from 'applications/automotor/views/espace-automotor-offer/TariffTable/TariffTable';
import Spinner from 'components/spinner/Spinner';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import { IValueItems } from 'applications/automotor/views/espace-automotor-offer/BtnTariff/ProformaNew';
import './AddReferenceModal.scss';

type Props = {
  setNewRefData: (data: IImportFileNew[]) => void
  setOpenModal: (openModal: boolean) => void
  tableData?
  importReference?: boolean
  proformaFile?: boolean
  referenceData?: boolean
}
type IParams = {
  reference: string;
  cod_fou: string
}
const c = 'add-reference-modal';

const AddReferenceModal: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();
  const [params, setParams] = useState<IParams>();
  const [valueItems, setValueItems] = useState<IValueItems>();
  const proformaFileData = JSON.parse(localStorage.getItem('proformaFileInProgress')!);
  const importReference = JSON.parse(localStorage.getItem('importReference')!);
  const referenceData = JSON.parse(localStorage.getItem('referenceData')!);
  const [data, setData] = useState<IImportFileNew[]>([]);
  const [loading, setLoading] = useState(false);
  const http = useHttp();

  useEffect(() => {
    if (params?.reference) {
      setLoading(true);
      const queryParams = {
        reference: params.reference,
        cod_fou: params.cod_fou
      };

      http.price
        .getPrice(queryParams)
        .then((res) => {

          const highestIndex = props.tableData.reduce((maxIndex, item) => {
            const partsIndex = (item.parts || []).reduce((maxPartsIndex, part) => {
              return part.index > maxPartsIndex ? part.index : maxPartsIndex;
            }, maxIndex);

            return partsIndex;
          }, -1);

          const manipulatedData = res.data.map((item) => {
            const updatedParts = (item.parts || []).map((part) => ({
              ...part,
              index: part.index + highestIndex + 50000,
            }));

            return {
              ...item,
              parts: updatedParts,
            };
          });

          setData(manipulatedData);

        })
        .finally(() => {
          setLoading(false);
        }).catch((err) => notify(err.message[0]));
    } else {
      const newData = [...JSON.parse(JSON.stringify(data))];

      setData(newData);
      setLoading(false);
    }
  }, [params]);

  function handleClick() {
    if (data.length) {
      const isCodeFouExists = data.some((item) =>
        item.parts.some((part) =>
          props.tableData.some((tableItem) =>
            tableItem.parts.some((tablePart) => tablePart.codfou === part.codfou && tablePart.reference === part.reference)
          )
        )
      );

      if (isCodeFouExists) {
        notify(t('_item_already_exists'));
      } else {

        if (props.importReference) {

          const updatedImportReference: IImportFileNewV2 = {
            data: [...data, ...importReference.data],  // actualizăm datele filtrate
            original_file: importReference?.original_file || '',
            filename: importReference?.filename || '',
          };

          localStorage.setItem('importReference', JSON.stringify(updatedImportReference));
          props.setNewRefData([...data, ...importReference.data]);
        }

        if (props.proformaFile) {
          const mergedAdditionalParts = proformaFileData.additionalParts || [];

          data.forEach((newItem) => {
            const existingIndex = mergedAdditionalParts.findIndex((item) => item.code_fou === newItem.code_fou);

            if (existingIndex !== -1) {
              // Merge parts if code_fou matches
              mergedAdditionalParts[existingIndex].parts = Array.from(
                new Set([...mergedAdditionalParts[existingIndex].parts, ...newItem.parts])
              );
            } else {
              // Add new item if code_fou doesn't match
              mergedAdditionalParts.push(newItem);
            }
          });

          localStorage.setItem('proformaFileInProgress', JSON.stringify({ ...proformaFileData, additionalParts: mergedAdditionalParts }));
          props.setNewRefData(data);
        }

        if (props.referenceData) {
          localStorage.setItem('referenceData', JSON.stringify([...data, ...referenceData]));
          props.setNewRefData([...data, ...referenceData]);
        }

        props.setOpenModal(false);
        notify(t('_success'), 'success');
      }
    }
  }

  useEffect(() => {
    if (valueItems !== undefined) {
      const newData = [...JSON.parse(JSON.stringify(data))];

      newData.forEach((items: IImportFileNew) => {
        items.parts.forEach((item) => {
          if (item.index === valueItems.index) {
            item.quantity = valueItems.quantity;
            item.total = Number(parseFloat(String(valueItems.quantity * Number(item.price))).toFixed(2));

            if (item.ind_equivalence) {
              item.ind_equivalence.quantity = valueItems.quantity;
              item.ind_equivalence.total = Number(parseFloat(String(valueItems.quantity * Number(item.ind_equivalence.price))).toFixed(2));
            }
          }
        });
      });

      if (newData.length) {
        setData(newData);
      }
    }
  }, [valueItems]);

  return (
    <div className={c}>
      <div className={`${c}__header`}>
        <div className={`${c}__header-title`}>{t('_add_reference')}</div>
        <div className={`${c}__header-close`}><FontAwesomeIcon icon={faClose} onClick={() => props.setOpenModal(false)} /></div>
      </div>
      <div className={`${c}__content`}>
        <div className={`${c}__content-search`}>
          <NewReferenceSearch setParams={setParams} />
        </div>
        <div className={`${c}__content-table ${loading ? 'is-loading' : ''}`}>
          {loading ? <Spinner class="extra-small" /> : (
            !!data.length &&
            <TariffTable
              loadingContent={loading}
              setValueItems={setValueItems}
              data={data}
              noFooter
            />

          )}
        </div>
      </div>
      {!!data.length && (
        <div className={`${c}__footer`}>
          <div className={`${c}__footer-btn yes`}>
            <DefaultButton text={t('_add')} onClick={handleClick} blue />
          </div>
          <div className={`${c}__footer-btn no`}>
            <DefaultButton text={t('_cancel')} onClick={() => props.setOpenModal(false)} red />
          </div>
        </div>
      )}
    </div>
  );
};

export default AddReferenceModal;