import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type ManufacturerSupplier = {
  manufacturer: number;
  supplier: number;
} | null;

const initialState: { infoManufacturerSupplier: ManufacturerSupplier } = {
  infoManufacturerSupplier: null,
};

export const manufacturerSupplier = createSlice({
  name: 'manufacturerSupplier',
  initialState,
  reducers: {
    setInfoManufacturerSupplier: (state, action: PayloadAction<ManufacturerSupplier>) => {
      state.infoManufacturerSupplier = action.payload;
    },
    removeInfoManufacturerSupplier: (state) => {
      state.infoManufacturerSupplier = null;
    },
  },
});

export const { setInfoManufacturerSupplier, removeInfoManufacturerSupplier } = manufacturerSupplier.actions;

export default manufacturerSupplier.reducer;
