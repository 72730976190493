import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  faBell,
  faBox,
  faDownload,
  faFileDownload,
  faCoins,
  faTags,
  faXmarkCircle
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { INotificationResponse, INotificationsData } from 'utils/hooks/http/notifications.http';
import { useOnClickOutside } from 'utils/hooks/useOnClickOutside';
import { Link } from 'react-router-dom';
import useHttp from 'utils/hooks/useHttp';
import Spinner from 'components/spinner/Spinner';
import Modal from 'components/modal/Modal';
import NotificationModal from 'applications/automotor/views/notifications/notification-modal/NotificationModal';
import { getMarketplaceBaseUrl, notify } from 'utils/marketplace';
import useTimerMessages from 'utils/hooks/useTimerMessages';
import './NotificationsDropdown.scss';
import moment from 'moment';

type Props = {
  centered?: boolean;
}

const NotificationsDropdown: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const http = useHttp();
  const time = useTimerMessages(30000);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(0);
  const [notifications, setNotifications] = useState<INotificationsData>();
  const [loading, setLoading] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] = useState<INotificationResponse>();
  const [openModal, setOpenModal] = useState(false);
  const [totalSeen, setTotalSeen] = useState(0);
  const ref = useRef() as React.MutableRefObject<HTMLDivElement>;
  const initialized = useRef(false);

  useOnClickOutside(ref, () => {
    if (!openModal) {
      setIsOpen(false);
    }
  });

  const observer = useRef<IntersectionObserver>();

  const lastNotificationRef = useCallback(
    (node) => {
      if (observer.current) { observer.current.disconnect(); }

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore && node === entries[0].target) {
          getNotifications();
        }
      });

      if (node) { observer.current.observe(node); }
    },
    [hasMore, page]
  );


  function getNotifications(interval?) {
    setLoading(true);
    http.notifications
      .getNotifications({ per_page: 3, page: interval ? 1 : page + 1, icon_notification: true })
      .then((res: INotificationsData) => {
        setNotifications((prevNotifications) => {
          let updatedData;

          if (prevNotifications) {
            updatedData = prevNotifications.data.concat(res.data.filter(item => {
              return !prevNotifications.data.some(prevItem => prevItem.id === item.id);
            }));
          } else {
            updatedData = res.data;
          }

          return {
            data: updatedData,
            page: res.page,
            total_count: res.total_count,
            per_page: res.per_page,
            total_count_seen: res.total_count_seen,
            total_pages: res.total_pages,
          };
        });
        setPage(res.page);

        const newTotalSeen = Number(res.total_count_seen);

        if (newTotalSeen !== totalSeen) {
          setTotalSeen(newTotalSeen);
        }

        setHasMore(res.page + 1 <= res.total_pages);
      })
      .catch((error) => {
        console.error('Error fetching notifications: ', error);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function markAsRead(id: number) {
    const params = {
      seen_ids: [Number(id)],
    };

    setNotifications((prev) => {
      if (!prev) { return prev; }

      const newData = { ...prev };

      const updatedData = newData.data?.filter((item) => {
        if (item.id === id) {
          if (!item.seen) {
            item.seen = true;

            return;
          }

          return;
        }

        return item;
      });

      return { ...newData, data: updatedData } as INotificationsData;
    });

    http.notifications.markAsRead(params);
  }

  function handleClick(item) {
    setModalData(item);
    setOpenModal(true);
    markAsRead(item.id);
  }

  function download(item) {
    const handleTariffDownload = () => {
      setLoadingDownload(item.id);

      http.automotorTariff.getFile(item.payload.file, { isNotification: true })
        .then((res) => {
          const element = document.createElement('a');
          const file = new Blob([res.data], { type: res.data.type });

          element.href = URL.createObjectURL(file);
          element.download = `${item.notification}_${moment().format('MM-DD-YY')}`;
          element.click();
        })
        .catch(() => {
          window.scrollTo({ top: 0, behavior: 'smooth' });
          notify(t('_error'));
        })
        .finally(() => {
          setLoadingDownload(null);
        });
    };

    const handleRegularDownload = () => {
      const url = getMarketplaceBaseUrl() + item.payload.file;
      const link = document.createElement('a');

      link.href = url;
      link.setAttribute('download', item.payload.original_file);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

    if (item.notification.toLocaleLowerCase() === 'tariff download') {
      handleTariffDownload();
    } else {
      handleRegularDownload();
    }
  }

  useEffect(() => {
    if (!initialized.current || !isOpen) {
      initialized.current = true;
      getNotifications(initialized.current ? time : null);
    }
  }, [time, isOpen]);


  return (
    <div className="notifications-dropdown">
      <div
        className={`notification-button-item ${isOpen ? 'open' : ''}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <FontAwesomeIcon icon={faBell} />
        {!!notifications?.total_count_seen && (
          <div className="notifications-count">{notifications?.total_count_seen}</div>
        )}
      </div>
      {isOpen && (
        <div ref={ref} className={`notifications-dropdown-content ${props.centered ? 'centered' : ''}`}>
          <div className="notification-title">{t('_new_notifications')}</div>

          <div id="scrollableDiv" className="notification-list">
            {notifications?.data.length ? notifications?.data.map((item, index) => {
              if (notifications.data.length === index + 1) {
                return (
                  <div
                    ref={lastNotificationRef}
                    key={item.id}
                    className={`notification-item ${!item.seen ? 'unread' : ''}`}
                  >
                    <div className="notification-icon-container">
                      <div className="notification-dropdown-icon">
                        {item.notification.toLocaleLowerCase() === 'order' && <FontAwesomeIcon className="notification-icon" icon={faBox} />}
                        {item.notification.toLocaleLowerCase() === 'wrong mapping' && <FontAwesomeIcon className="notification-icon" icon={faXmarkCircle} />}
                        {item.notification.toLocaleLowerCase() === 'tariff' && <FontAwesomeIcon className="notification-icon" icon={faTags} />}
                        {item.notification.toLocaleLowerCase() === 'tariff download' && <FontAwesomeIcon className="notification-icon" icon={faFileDownload} />}
                        {item.notification.toLocaleLowerCase() === 'coefficient' && <FontAwesomeIcon className="notification-icon" icon={faCoins}/>}

                      </div>
                    </div>
                    <div className="notification-info">
                      <div className="notification-info-text">
                        <div className="notification-message">{item.message}</div>
                        <div className="notification-date">{item.created_at}</div>
                      </div>
                      <div className="notification-actions">
                        {item.payload.file && <div className="notification-file" >
                          <span className="notification-download-icon">{loadingDownload === item.id ? <Spinner class="extra-small download-notification-spinner" /> : <FontAwesomeIcon icon={faDownload} onClick={() => download(item)} />}</span>
                          <span className="notification-filename">{item.payload.original_file || t('_file')}</span>
                        </div>}
                        <div className="notifications-btns">
                          <div className="notification-action" onClick={() => handleClick(item)}>{t('_read')}</div>
                          {!item.seen && (
                            <div className="notification-action" onClick={() => markAsRead(item.id)}>{t('_mark_as_read')}</div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              } else {
                return (
                  <div
                    key={item.id}
                    className={`notification-item ${!item.seen ? 'unread' : ''}`}
                  >
                    <div className="notification-icon-container">
                      <div className="notification-dropdown-icon">
                        {item.notification.toLocaleLowerCase() === 'order' && <FontAwesomeIcon className="notification-icon" icon={faBox} />}
                        {item.notification.toLocaleLowerCase() === 'wrong mapping' && <FontAwesomeIcon className="notification-icon" icon={faXmarkCircle} />}
                        {item.notification.toLocaleLowerCase() === 'tariff' && <FontAwesomeIcon className="notification-icon" icon={faTags} />}
                        {item.notification.toLocaleLowerCase() === 'tariff download' && <FontAwesomeIcon className="notification-icon" icon={faFileDownload} />}
                        {item.notification.toLocaleLowerCase() === 'coefficient' && <FontAwesomeIcon className="notification-icon" icon={faCoins}/>}
                      </div>
                    </div>
                    <div className="notification-info">
                      <div className="notification-info-text">
                        <div className="notification-message">{item.message}</div>
                        <div className="notification-date">{item.created_at}</div>
                      </div>
                      <div className="notification-actions">
                        {item.payload.file && <div className="notification-file" >
                          <span className="notification-download-icon">{loadingDownload === item.id ? <Spinner class="extra-small download-notification-spinner" /> : <FontAwesomeIcon icon={faDownload} onClick={() => download(item)} />}</span>
                          <span className="notification-filename">{item.payload.original_file}</span>
                        </div>}
                        <div className="notifications-btns">
                          <div className="notification-action" onClick={() => handleClick(item)}>{t('_read')}</div>
                          {!item.seen && (
                            <div className="notification-action" onClick={() => markAsRead(item.id)}>{t('_mark_as_read')}</div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
            }
            ) : !loading && <div className="no-notifications">{t('_no_new_notifications')}</div>
            }
            {loading && <Spinner class="extra-small" />}
          </div>
          <Link className="notification-all" to="/automotor-online/notifications">
            {t('_see_all_notifications')}
          </Link>
        </div>
      )}
      <Modal
        openModal={openModal}
        setOpenModal={setOpenModal}
        childrenComponent={<NotificationModal data={modalData} setOpen={setOpenModal} />}
      />
    </div>
  );
};

export default NotificationsDropdown;