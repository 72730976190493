import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import MiniDropdown from 'components/dropdowns/MiniDropdown/MiniDropdown';

import { IDropdownModel } from 'components/dropdowns/Dropdown.model';
import { NavLink, useNavigate } from 'react-router-dom';
import { getCountryFromLocale, getLanguageFromLocale, getLanguageFromLocalStorage } from 'utils/locale';
import { languages } from 'utils/constants/locales';
import RELIABLE_LOGO from 'assets/images/reliable-logo.svg';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import useHttp from 'utils/hooks/useHttp';
import { IUserPut } from 'utils/hooks/http/user.http';
import { notify } from 'utils/marketplace';
import useDefaultRouteForRole from 'utils/hooks/useDefaultRouteForRole';
import LogoutButton from 'components/buttons/LogoutButton/LogoutButton';
import { usePermission } from 'utils/usePermission';
import { ROLE_CLIENT_AUTOMOTOR_GROUP_MANAGER } from 'utils/constants/roles';
import AutomotorClientManagerGrupClientButton from 'components/buttons/AutomotorClientManagerGrupClientButton/AutomotorClientManagerGrupClientButton';
import NotificationsDropdown from 'components/buttons/NotificationsDropdown/NotificationsDropdown';
import CartButtonEspaceAutomotor from 'components/buttons/CartButtonEspaceAutomotor/CartButtonEspaceAutomotor';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import CartButton from 'components/buttons/CartButton/CartButton';
import './EspaceAutomotorNavbar.scss';


type Props = {};

const EspaceAutomotorNavbar: React.FC<Props> = () => {
  const { user } = useSelector((state: RootState) => state.userData);
  const http = useHttp();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const { isMobile } = useDeviceDetect();
  const [selectedLanguage, setSelectedLanguage] = useState<IDropdownModel>(getLanguageFromLocalStorage() ?? languages[0]);
  const { quantityToolbar } = useSelector((state: RootState) => state.cart);
  const { quantityEspaceAutomotor } = useSelector((state: RootState) => state.cartEspaceAutomotor);

  useEffect(() => {
    const language = getLanguageFromLocale(i18n);

    if (language) {
      setSelectedLanguage(language);
    }
  }, [i18n.language]);


  const changeLanguage = (item: IDropdownModel) => {
    const country = getCountryFromLocale(i18n);

    if (!country) {
      return;
    }

    const locale = `${item.id}-${country.id}`;

    const userData: IUserPut = {
      language: String(item.id),
    };

    try {
      http.user.putUser(userData);
    } catch (e) {
      notify('Error');
    }

    localStorage.setItem('locale', locale);
    i18n.changeLanguage(locale);
  };

  const defaultLinkForRole = useDefaultRouteForRole();
  const CLIENT_AUTOMOTOR_GROUP_MANAGE = usePermission([ROLE_CLIENT_AUTOMOTOR_GROUP_MANAGER]);


  return (
    <div className="espace-automotor-navbar-container">
      <div className="navbar">
        <div className="container">
          <NavLink to="/automotor-online" className="logos">
            <div className="garage-michel">
              {user?.client_logo && <img className="client-logo" src={user?.client_logo} alt="espace-logo" />}
              <p className="user-name"> {user && user.client_name ? <span>{user?.client_name}</span> : null}</p>
            </div>
            {!isMobile && <img src={RELIABLE_LOGO} width="87" height="10" alt="espace-logo" />}
          </NavLink>
          {/* <div className="e-reliable-logo-navbar">
            <img src={E_RELIABLE_AUTOMOTOR_ONLINE_LOGO} alt="" />
          </div> */}
          <div className="title-automotor">
            <div>
              <Trans
                i18nKey={t('_automotor_portal').toString()}
                components={{ b: <b /> }}
              />
            </div>
            <div className="subtitle">
              <span className="text-ellipsis">{t('_espace_supplier_description')}</span>
            </div>

          </div>
          <div className="right-part-navbar">
            {isMobile && <img src={RELIABLE_LOGO} width="87" height="10" alt="espace-logo" onClick={() => navigate(defaultLinkForRole)} />}
            <div className="navbar-actions-container">
              <div className="user-settings">
                <div className="mini-dropdowns">
                  {CLIENT_AUTOMOTOR_GROUP_MANAGE && <AutomotorClientManagerGrupClientButton />}
                  <div className="language-container">
                    <div className="label">{t('_language')}</div>
                    <MiniDropdown onClick={changeLanguage} content={selectedLanguage?.id} data={languages} />
                  </div>
                </div>
              </div>

              <div className="notifications-dropdown">
                <NotificationsDropdown />
              </div>
              <CartButtonEspaceAutomotor quantityToolbar={quantityEspaceAutomotor} />
              {/* {
                location.pathname.includes('/automotor-online')
                  ?
                  <CartButtonEspaceAutomotor quantityToolbar={quantityEspaceAutomotor} />
                  :
                  <CartButton quantityToolbar={quantityToolbar} />
              } */}
              <div className="logout-button">
                <LogoutButton />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

EspaceAutomotorNavbar.displayName = 'EspaceAutomotorNavbar';

export default EspaceAutomotorNavbar;
