import React, { useCallback, useEffect, useRef, useState } from 'react';
import './ModalDlrnHernrCodfouByspecifyingReferenceContent.scss';
import IconItem from '../../../../../../components/Item/IconItem/IconItem';
import { faClose, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { setDlrnHernrCodfouByspecifyingReferenceActive } from 'store/slices/dlrnHernrCodfouByspecifyingReference.slice';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useHttp from 'utils/hooks/useHttp';
import { IOption } from 'interfaces/select';
import Select from 'react-select';

import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import { useDropzone } from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios, { CancelTokenSource } from 'axios';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';


type Props = {
  setOpenModal: (e: boolean) => void;
};


type ICsvForm = {
  file: File | null | undefined;
  hernr: IOption;
  equivalent_hernr: IOption[];
};
const ModalDlrnHernrCodfouByspecifyingReferenceContent: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const http = useHttp();
  const [providers, setProviders] = useState<IOption[]>([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [filteredEquivalentOptions, setFilteredEquivalentOptions] = useState<IOption[]>([]);
  const [pageFilteredEquivalentOptions, setPageFilteredEquivalentOptions] = useState(1);
  const [hasMoreFilteredEquivalentOptions, setHasMoreFilteredEquivalentOptions] = useState(true);
  const [isLoadingMoreFilteredEquivalentOptions, setIsLoadingMoreFilteredEquivalentOptions] = useState(false);
  const [searchTermFilteredEquivalentOptions, setSearchTermFilteredEquivalentOptions] = useState<string>('');

  const { typeEquivalence } = useSelector((state: RootState) => state.dlrnHernrCodfouByspecifyingReference);

  const { control, getValues, watch, handleSubmit, setValue, formState: { errors } } = useForm<ICsvForm>({
    mode: 'onSubmit',
    defaultValues: {
      file: null,
    },
  });


  const onDrop = useCallback((acceptedFile) => {
    if (acceptedFile.length > 0) {
      setValue('file', acceptedFile[0], { shouldValidate: true });
    }
  }, [setValue]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      'text/csv': ['.xls', '.xlsx'],
      'application/vnd.ms-excel': ['.xls', '.xlsx'],
    },
  });


  const cancelTokenSource = useRef<CancelTokenSource | null>(null);
  const cancelTokenSourceFilteredEquivalentOptions = useRef<CancelTokenSource | null>(null);

  const getSupplierForType = (per_page = 100, page: number, searchTerm: string) => {
    setIsLoadingMore(true);

    const params: { per_page: number; page: number; q?: string } = { per_page, page };

    if (searchTerm.trim() !== '') {
      params.q = searchTerm;
      setProviders([]); // Resetăm lista de furnizori
    }

    // Dacă există o cerere anterioară, o anulăm
    if (cancelTokenSource.current) {
      cancelTokenSource.current.cancel('Request canceled due to new request');
    }

    // Creăm un nou cancel token pentru cererea curentă
    cancelTokenSource.current = axios.CancelToken.source();
    let route;


    if (typeEquivalence === 'IAM') {
      route = http.checkEquivalenceHttp.getEquivalenceAIM(params, cancelTokenSource.current.token);
    }

    if (typeEquivalence === 'OEM') {
      route = http.checkEquivalenceHttp.getEquivalenceOEM(params, cancelTokenSource.current.token);
    }


    // Verificăm dacă `route` este definit și este o funcție
    if (route) {
      route.then(res => {
        if (res.data.total_pages === page && page !== 1) {
          setHasMore(false); // Dacă nu mai sunt date de încărcat
        } else {
          // Filtrăm duplicatele folosind `value` ca identificator
          const newProviders = res.data.data.map((item) => ({
            value: item.hernr,
            label: item.name
          }));

          setProviders((prev) => [
            ...prev,
            ...newProviders.filter((newProvider) => !prev.some((prevProvider) => prevProvider.value === newProvider.value))
          ]);
        }
      })
        .catch((error) => {
          if (axios.isCancel(error)) {
            console.log('Request was canceled');
          } else {
            setPage(1); // Dacă cererea a eșuat, resetăm pagina
            setProviders([]); // Resetăm lista de furnizori
            console.log('Request failed ' + typeEquivalence + ':', error); // Log pentru eroare
          }
        })
        .finally(() => {
          setIsLoadingMore(false);
        });
    } else {
      // Log pentru cazul când `typeEquivalence` nu este nici 'AIM', nici 'OEM'
      console.error('Invalid typeEquivalence:', typeEquivalence);
      setIsLoadingMore(false);
    }
  };


  useEffect(() => {
    if (searchTerm.length >= 2) {
      setPage(1);
      const timer = setTimeout(() => {
        getSupplierForType(100, 1, searchTerm);
      }, 200);

      return () => clearTimeout(timer);
    }
  }, [searchTerm]);

  const handleScrollToBottom = () => {
    if (hasMore && !isLoadingMore) {
      setPage((prevPage) => prevPage + 1); // Creștem pagina
    }
  };

  // Folosim useEffect pentru a încărca date pentru fiecare schimbare a paginii
  useEffect(() => {
    if (page >= 1 && searchTerm.length === 0) {
      // Nu resetează providerii dacă searchTerm este gol (pentru scroll)
      getSupplierForType(100, page, searchTerm);
    }
  }, [page, searchTerm]); // Depinde de page și searchTerm


  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const getSupplier = (per_page = 100, page: number, searchTerm: string) => {
    setIsLoadingMoreFilteredEquivalentOptions(true);

    const params: { per_page: number; page: number; q?: string } = { per_page, page };

    if (searchTerm.trim() !== '') {
      params.q = searchTerm;
      setFilteredEquivalentOptions([]); // Resetăm lista de furnizori
    }

    // Dacă există o cerere anterioară, o anulăm
    if (cancelTokenSourceFilteredEquivalentOptions.current) {
      cancelTokenSourceFilteredEquivalentOptions.current.cancel('Request canceled due to new request');
    }

    // Creăm un nou cancel token pentru cererea curentă
    cancelTokenSourceFilteredEquivalentOptions.current = axios.CancelToken.source();


    let route;


    if (typeEquivalence === 'IAM') {
      route = http.checkEquivalenceHttp.getEquivalenceSupplier(params, cancelTokenSourceFilteredEquivalentOptions.current.token);
    }

    if (typeEquivalence === 'OEM') {
      route = http.checkEquivalenceHttp.getEquivalenceAIM(params, cancelTokenSourceFilteredEquivalentOptions.current.token);
    }

    if (route) {
      route.then(res => {
        if (res.data.total_pages === page && page !== 1) {
          setHasMoreFilteredEquivalentOptions(false); // Dacă nu mai sunt date de încărcat
        } else {
          // Filtrăm duplicatele folosind `value` ca identificator
          const newProviders = res.data.data.map((item) => ({
            value: item.hernr,
            label: item.name
          }));

          setFilteredEquivalentOptions((prev) => [
            ...prev,
            ...newProviders.filter((newProvider) => !prev.some((prevProvider) => prevProvider.value === newProvider.value))
          ]);
        }
      })
        .catch((error) => {
          if (axios.isCancel(error)) {
            console.log('Request was canceled');
          } else {
            setPageFilteredEquivalentOptions(1); // Dacă cererea a eșuat, resetăm pagina
            setFilteredEquivalentOptions([]); // Resetăm lista de furnizori
            console.log('Request failed supplier:', error); // Log pentru eroare
          }
        })
        .finally(() => {
          setIsLoadingMoreFilteredEquivalentOptions(false);
        });
    } else {
      // Log pentru cazul când `typeEquivalence` nu este nici 'AIM', nici 'OEM'
      console.error('Invalid typeEquivalence Supplier');
      setIsLoadingMoreFilteredEquivalentOptions(false);
    }


  };


  const handleScrollToBottomFilteredEquivalentOptions = () => {
    if (hasMoreFilteredEquivalentOptions && !isLoadingMoreFilteredEquivalentOptions) {
      setPageFilteredEquivalentOptions((prevPage) => prevPage + 1); // Creștem pagina
    }
  };

  // Folosim useEffect pentru a încărca date pentru fiecare schimbare a paginii
  useEffect(() => {
    if (pageFilteredEquivalentOptions >= 1 && searchTermFilteredEquivalentOptions.length === 0) {

      // Nu resetează providerii dacă searchTerm este gol (pentru scroll)
      getSupplier(100, pageFilteredEquivalentOptions, searchTermFilteredEquivalentOptions);
    }
  }, [pageFilteredEquivalentOptions, searchTermFilteredEquivalentOptions]); // Depinde de page și searchTerm

  useEffect(() => {
    if (searchTermFilteredEquivalentOptions.length >= 2) {
      setPageFilteredEquivalentOptions(1);
      const timer = setTimeout(() => {
        getSupplier(100, 1, searchTermFilteredEquivalentOptions);
      }, 200);

      return () => clearTimeout(timer);
    }
  }, [searchTermFilteredEquivalentOptions]);


  function search() {
    setIsLoading(true);
    const values = getValues();

    // Construim un obiect FormData
    const formData = new FormData();

    formData.append('hernr', values.hernr.value);
    formData.append('type', typeEquivalence);

    if (Array.isArray(values.equivalent_hernr) && values.equivalent_hernr.length) {
      values.equivalent_hernr.forEach((item, index) => {
        formData.append(`equivalent_hernr[${index}]`, item.value);
      });
    }

    if (values.file) {
      formData.append('file', values.file);
    }

    http.buyerHttp.postList(formData).then((res) => {

      const blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');

      link.href = url;
      link.setAttribute('download', values.hernr.label + '_CHECK_EQUIVALENCES.xlsx');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

    }).finally(() => {
      setIsLoading(false);
      props.setOpenModal(false);
      setDlrnHernrCodfouByspecifyingReferenceActive(false);
    });
  }

  return (

    <div className="list-dlrn-hernr-codfou-by-specifying-reference" >
      <div className="modal-dialog">
        <div className="close">
          <IconItem icon={faClose} onClick={() => { props.setOpenModal(false); setDlrnHernrCodfouByspecifyingReferenceActive(false); }} />
        </div>
        <div className="list-title">
          {typeEquivalence == 'IAM' ? t('_check_equivalences_for_iam') : t('_check_equivalences_for_oem')}
        </div>
        <form onSubmit={handleSubmit(search)} >
          <div className="list-content">
            <div className={`price-file-upload-container ${errors.file && 'invalid'}`}>
              <i className="fas fa-folder-open price-folder-upload" />
              <div {...getRootProps()} className="price-title-upload ">
                {t('_drag_drop_here')}
                <br />
                <strong className="browse-files">{t('_csv_browse')}</strong>
              </div>
              <Controller
                control={control}
                name="file"
                rules={{ required: t('_file_required') }}
                render={({ field }) => (
                  <input
                    {...getInputProps({
                      onChange: (e) => field.onChange(e.target.files?.[0] || null),
                    })}
                    accept="text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    type="file"
                    style={{ display: 'none' }}
                  />
                )}
              />
            </div>
            {
              !errors.file && watch('file') &&
              <div className="uploaded-file">
                <FontAwesomeIcon className="file-icon" icon={faFileExcel} />
                <div className="file-data">
                  <div className="file-name">{watch('file')?.name || ''}</div>
                  <FontAwesomeIcon className="remove-file" icon={faClose} onClick={() => setValue('file', null, { shouldValidate: true })} />
                </div>
              </div>
            }
            <div className="codfou-reference">
              <div className="list-row">
                <div className="list-row-label">{t('_provider')}<span>*</span></div>
                <div className="provider-dropdown-item">
                  <Controller
                    control={control}
                    name="hernr"
                    rules={{ required: true }}
                    render={({ field: { onChange } }) => {
                      return (
                        <Select
                          className={`select-dropdown ${errors.hernr && 'required'}`}
                          onChange={(val) => {
                            onChange(val); // Setează valoarea selectată în formular
                          }}
                          options={providers}
                          placeholder={t('_provider')}
                          styles={{
                            menu: (provided) => ({ ...provided, zIndex: 50 })
                          }}
                          onMenuScrollToBottom={handleScrollToBottom}
                          isLoading={isLoadingMore}
                          onInputChange={(val) => {
                            setSearchTerm(val);

                          }}
                        />
                      );
                    }}
                  />
                </div>


              </div>

            </div>

            <div className="separator"></div>

            <div className="equivalent-codfou">
              <div className="list-row">
                <div className="list-row-label">{t('_provider') + ' ' + t('_equivalent')}<span>*</span></div>
                <div className="provider-dropdown-item">
                  <Controller
                    control={control}
                    name="equivalent_hernr"
                    rules={{ required: true }}
                    render={({ field: { onChange } }) => {
                      return (
                        <Select
                          className={`select-dropdown ${errors.equivalent_hernr && 'required'}`}
                          onChange={(val) => {
                            onChange(val);
                          }}
                          options={filteredEquivalentOptions}
                          placeholder={t('_provider')}
                          styles={{
                            menu: provided => ({ ...provided, zIndex: 50 })
                          }}
                          isMulti
                          onMenuScrollToBottom={handleScrollToBottomFilteredEquivalentOptions}
                          isLoading={isLoadingMoreFilteredEquivalentOptions}
                          onInputChange={(val) => {
                            setSearchTermFilteredEquivalentOptions(val);

                          }}
                        />
                      );
                    }}
                  />
                </div>

              </div>
            </div>
          </div>

          <div className="action">
            <DefaultButton isloading={isLoading} text={t('_search')} blue />
          </div>
        </form >

      </div>


    </div >
  );
};

export default ModalDlrnHernrCodfouByspecifyingReferenceContent;